@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "./abstracts/theme.module";

:root {
  @include printVariables();
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-size: 15px;
  line-height: 1.4;
  color: var(--colorACACAC);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  // font-family: Arial, Helvetica, sans-serif;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(121.15deg,
      #1c1854 6.04%,
      #081440 95.29%) !important;
  overflow-x: hidden;
}

// #__next {
//     overflow-x: hidden;
// }
strong,
b {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #00a5d0;
  display: inline-block;
  text-decoration: none;
}

a:hover {
  color: #00a5d0;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
  padding: 0;
  margin: 0;
  color: var(--color07171E);
}

h1 {
  text-transform: capitalize;
  line-height: 1;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  padding: 0;
  position: relative;
  list-style: none;
}

button {
  .MuiTouchRipple-root {
    display: none;
  }
}

a,
button {
  transition: 0.3s all ease-in-out 0s;
  -webkit-transition: 0.3s all ease-in-out 0s;
  -moz-transition: 0.3s all ease-in-out 0s;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
  padding: 0px 60px;
  background: transparent;
  width: 100%;
  padding-right: 25px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 35px;
  height: 53px;
  color: var(--white);

  @media (max-width: 575px) {
    padding-left: 60px;
  }

  @media (max-width: 479px) {
    font-size: 14px;
  }

  @media (max-width: 360px) {
    font-size: 12px;
    padding-right: 10px;
  }
}

textarea {
  padding: 0px 60px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 35px;
  height: 53px;
  color: var(--white);
}

select {
  background: url(/assets/images/down-arrow.svg) right 10px center no-repeat;
  background-size: 20px;
}

select[multiple] {
  background: transparent;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1 !important;
  color: var(--white);
}

::-moz-placeholder {
  opacity: 1 !important;
  color: var(--white);
}

:-ms-input-placeholder {
  opacity: 1 !important;
  color: var(--white);
}

:-moz-placeholder {
  opacity: 1 !important;
  color: var(--white);
}

button,
input[type="button"],
input[type="submit"] {
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
  background: #51bff0;
  border: 1px solid #51bff0;
  transition: 0.3s all ease-in-out 0s;
  -webkit-transition: 0.3s all ease-in-out 0s;
  -moz-transition: 0.3s all ease-in-out 0s;
  cursor: pointer;
  display: inline-block;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  background: #51bff0;
  color: #ffffff;
  outline: none;
  text-decoration: none;
}

.MuiContainer-root {
  @media (min-width: 1200px) {
    max-width: 1185px;
  }
}

.MuiButtonBase-root {
  text-transform: none !important;
}

.cmn_gap {
  padding: 80px 0;

  @media (max-width: 991px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
}

.cmn_gap_top {
  padding-top: 0 !important;
}

.cmn_gap_btm {
  padding-bottom: 0 !important;
}

.cmn_head {
  margin-bottom: 50px;
  text-align: center;

  h2 {
    font-size: 40px;
    text-transform: none;
    color: #202020;

    @media (max-width: 1200px) {}
  }
}

.MuiContainer-root {
  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  &.cus_container {
    @media (min-width: 1400px) {
      max-width: 1470px;
    }
  }
}

// ====================
.global_slick {
  .slick-dots {
    text-align: start;
    bottom: -65px;

    @media (max-width: 1199px) {
      position: static;
      margin-top: 20px;
    }

    li {
      width: 14px;
      height: 14px;

      button {
        border: 1px solid rgba(255, 255, 255, 0.5);
        width: 10px;
        height: 10px;
        line-height: 10px;
        background: transparent;
        border-radius: 100%;
        margin: auto;

        &::before {
          font-size: 0;
          line-height: 10px;

          width: auto;
          height: auto;
          content: "";
          text-align: center;
          border: 0;
          opacity: 1;
          background: transparent;
          border-radius: 100%;
        }
      }

      &.slick-active {
        button {
          opacity: 1;
          background: linear-gradient(158.45deg,
              #0bd3d3 17.08%,
              #ab2aa9 64.21%,
              #f890e7 97.73%);

          &::before {
            // opacity: 1;
            // background: linear-gradient(
            //   158.45deg,
            //   #0bd3d3 17.08%,
            //   #ab2aa9 64.21%,
            //   #f890e7 97.73%
            // );
            // outline: 1px solid rgba(11, 211, 211, 1);
            // outline-offset: 3px;
            width: auto;
            height: auto;
            border: 1px solid rgba(11, 211, 211, 1);
            border-radius: 100%;
            content: "";
            top: -4px;
            left: -3px;
            right: -3px;
            bottom: -2px;
          }
        }
      }
    }
  }

  .slick-prev {
    background-image: url("../public/assets/images/prev.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    left: -40px;
    width: 30px;
    height: 30px;

    &::before {
      content: "";
    }

    &:hover {
      background: url("../public/assets/images/prev2.svg") center no-repeat transparent;

      background-size: 30px;
    }

    &:focus {
      background: url("../public/assets/images/prev2.svg") center no-repeat transparent;

      background-size: 30px;
    }
  }

  .slick-next {
    background-image: url("../public/assets/images/next.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    right: -40px;
    width: 30px;
    height: 30px;

    &::before {
      content: "";
    }

    &:hover {
      background: url("../public/assets/images/next2.svg") center no-repeat transparent;

      background-size: 30px;
    }

    &:focus {
      background: url("../public/assets/images/next2.svg") center no-repeat transparent;

      background-size: 30px;
    }
  }
}

.body_content {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 116px;

  @media (max-width: 1199px) {
    min-height: inherit;
    padding-top: 91px;
  }

  @media (max-width: 899px) {
    padding-top: 76px;
  }

  @media (max-width: 479px) {
    padding-top: 75px;
  }

  @media (max-width: 359px) {
    padding-top: 70px;
  }

  .body_img1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%;
    z-index: -1;
  }

  .body_img2 {
    position: absolute;
    top: 0;
    right: 9%;
    width: 60%;
    z-index: -1;
  }
}

.dashRoundMenu {
  margin-top: 11px;
  background-color: transparent;

  .MuiMenu-paper {
    background: linear-gradient(45deg,
        rgba(73, 50, 147, 1) 0%,
        rgba(43, 42, 102, 1) 100%);
    box-shadow: 0px 1.1966px 29.915px rgba(69, 42, 124, 0.1);
    padding: 30px 29px 30px 17px;
    border-radius: 18px;

    ul {
      padding: 0;

      li {
        // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 0;
        position: relative;
        padding: 0;
        color: var(--white);
        transition: 0.3s all ease;
        padding-bottom: 23px;
        margin-bottom: 23px;
        min-height: inherit;

        @media (max-width: 767px) {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }

        @media (max-width: 479px) {
          padding-bottom: 15px;
          margin-bottom: 15px;
        }

        &::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
        }

        &:hover {
          background-color: transparent;
          color: rgba(255, 255, 255, 0.5);
        }

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.button_new_htt {
  .MuiButtonBase-root {
    min-width: 158px;
    padding: 19px 12px;

    @media (max-width: 899px) {
      padding: 15px 12px;
    }

    @media (max-width: 767px) {
      padding: 13px 12px;

      .MuiTypography-root {
        font-size: 13px;
      }
    }

    @media (max-width: 479px) {
      min-width: 142px;
    }
  }
}

.custom_cmm_btns {
  .MuiButtonBase-root {
    padding: 19px 28px;
    min-width: 158px;

    @media (max-width: 899px) {
      padding: 15px 25px;
    }

    @media (max-width: 767px) {
      padding: 15px 23px;

      .MuiTypography-root {
        font-size: 13px;
      }
    }

    @media (max-width: 479px) {
      padding: 15px 20px;
    }
  }

  .deleteButton {
    background: var(--primaryRed);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2em;
    line-height: 1.1;
    text-transform: uppercase;
    padding: 19px 28px;
    min-width: 158px;
    color: var(--white);
    border-radius: 120px;
    margin-left: 10px;

    &:hover {
      background: var(--primaryRed);
    }
  }

  &.start_cmn_customs {
    .MuiButtonBase-root {
      @media (max-width: 575px) {
        display: table;
        margin: 0 auto;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 479px) {
        padding: 11px 20px;
      }
    }
  }
}

.cmn_labelhead {
  color: var(--colorE0D6FF);
  margin-bottom: 19px;
  display: block;
  font-size: 14px;
}

.cmn-select-boxpt {
  &.new_select_icon {
    .dashSelect_sm_new {
      .MuiInputBase-root {
        .MuiSelect-select {
          background: url(/assets/images/down_arrow_vectors.svg) no-repeat center right 20px;
          background-size: 13px;
        }
      }
    }
  }
}

.common_input_styld {
  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 3px 23px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 35px;
        height: 53px;
        color: var(--white);
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }
  }
}

.label_input_inner_r {
  position: relative;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: none;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-right: 60px;
    }
  }

  .label_input_inner_r_btn {
    position: absolute;
    right: 20px;
    top: 18px;
    line-height: 1;

    button {
      min-width: inherit;
      width: auto;
      height: auto;
      padding: 0;
    }
  }
}

.cmn_mi_checked {
  .MuiSwitch-root {
    padding: 0;
    width: 48px;
    height: 30px;

    .MuiButtonBase-root {
      padding: 0;
      color: #0bd3d3;
      transform: inherit;
      position: absolute;
      right: 0;
      left: inherit;
      top: 5px;
      margin: 0 4px;

      .MuiSwitch-thumb {
        width: 21px;
        height: 21px;
      }

      &:not(.Mui-checked) {
        left: 0;
        right: inherit;
        color: #fff;
      }
    }

    .MuiSwitch-track {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
      border-radius: 50px;
      -webkit-border-radius: 50px;
    }
  }
}

.cmn_modal_wraps {
  .MuiBackdrop-root {
    background: rgba(13, 20, 69, 0.64);
    backdrop-filter: blur(3.5px);
  }

  .MuiPaper-root {
    border-radius: 18px !important;
    width: 100%;
    max-width: 515px;
    margin-left: auto;
    margin-right: auto;
    background: url("/assets/images/overlay_back_sec.jpg") repeat top center;
    background-size: 100% 100%;
    padding: 0;
    color: #fff;

    @media (max-width: 899px) {
      height: auto;
      max-height: 90%;
    }

    @media (max-width: 767px) {
      max-width: calc(100% - 15vw);
    }

    >* {
      .MuiButtonBase-root.MuiIconButton-root {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 15px;
        right: 26px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background: #525a9e;
        color: #fff;

        svg {
          font-size: 16px;
        }
      }
    }

    .MuiDialogContent-root {
      padding: 68px 67px;

      @media (max-width: 767px) {
        padding: 48px 30px;
      }

      @media (max-width: 479px) {
        padding: 38px 15px;
        padding-top: 48px;
      }
    }
  }
}

.games_modal_round {
  text-align: center;

  .games_modal_round_head {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 33px;
    margin-bottom: 33px;

    @media (max-width: 767px) {
      padding-bottom: 23px;
      margin-bottom: 23px;
    }

    h4 {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 600;
      color: #ffffff;

      @media (max-width: 479px) {
        font-size: 20px;
      }
    }
  }
}

.games_modal_round_btms {
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    margin-right: 14px;

    @media (max-width: 479px) {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    .MuiButtonBase-root {
      min-width: 152px;
      letter-spacing: 0.2em;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      padding: 18px 15px;
      text-transform: uppercase !important;
      background: #0bd3d3;
      border-radius: 38px;
      -webkit-border-radius: 38px;
      color: #100e48;

      @media (max-width: 479px) {
        min-width: 112px;
        padding: 12px 13px;
      }

      &.no_btn {
        background: #f890e7;
      }

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}

.cmn_inner_gap_brdr {
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.custom_cmm_btns {
  &.wthd {
    .MuiButtonBase-root {
      padding: 19px 38px;

      @media (max-width: 479px) {
        padding: 15px 28px;
      }
    }
  }

  &.right_fit {
    margin-left: auto;
    display: table;
  }
}

.inner_box_sections {
  >* {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px 0;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.noIconPadding {
  input {
    padding: 0 26px;

    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }
}

.tabss_modular_sec {
  .tabss_modular_sec_top {
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .MuiTabs-root {
      min-height: inherit;
      overflow: inherit;

      .MuiTabs-scroller {
        overflow: inherit !important;

        .MuiTabs-flexContainer {
          overflow-x: auto;

          button {
            padding: 0;
            margin: 0;
            min-height: inherit;
            min-width: 208px;
            padding: 18px 32px;
            background: rgba(255, 255, 255, 0.09);
            color: #ffffff;
            border-radius: 38px;
            -webkit-border-radius: 38px;
            letter-spacing: 0.2em;
            text-transform: uppercase !important;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 500;
            margin-right: 21px;

            @media (max-width: 899px) {
              min-width: 178px;
              padding: 14px 22px;
            }

            @media (max-width: 575px) {
              min-width: 148px;
              padding: 12px 19px;
              font-size: 12px;
            }

            @media (max-width: 479px) {
              min-width: 128px;
              padding: 11px 18px;
              font-size: 12px;
              margin-right: 11px;
            }

            &:last-child {
              margin-right: 0;
            }

            &.Mui-selected {
              background: #0bd3d3;
              color: #100e48;
            }
          }
        }

        .MuiTabs-indicator {
          display: none;
        }
      }
    }
  }

  .tabss_modular_sec_btms {
    padding-top: 30px;

    >* {
      >* {
        padding: 0;
      }
    }
  }
}

.upcoming_session_Wrapper {
  .MuiPaper-root {
    max-width: 630px;

    @media (max-width: 767px) {
      max-width: calc(100% - 20vw);
    }
  }
}

.upcoming_session_Wrapper_new {
  .MuiPaper-root {
    max-width: 800px;

    @media (max-width: 767px) {
      max-width: calc(100% - 20vw);
    }
  }
}

.session_upcomings_rows {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -21px;
  margin-bottom: -20px;

  @media (max-width: 479px) {
    margin: 0;
  }

  .session_upcomings_cols {
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: 0 21px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: 100%;
    }

    @media (max-width: 479px) {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 15px;
      line-height: 1.3;
      font-weight: 500;
      color: #0bd3d3;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p,
    li,
    .mode_sec_wrap {
      font-size: 15px;
      line-height: 1.7;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        margin-bottom: 2px;
        position: relative;
        padding-left: 11px;

        &:last-child {
          margin-bottom: 0;
        }

        &:after {
          position: absolute;
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #fff;
          left: 0;
          top: 11px;
        }
      }
    }

    .mode_sec_wrap {
      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
}

.start_btn_ssn {
  text-align: center;
  margin-top: 46px;
}

.deleteBtn {
  &.lrgg {
    img {
      width: 20px;
    }
  }
}

// location modal 31-05-23
.locationModal {
  z-index: 9;

  .MuiPaper-root {
    max-width: 630px;

    @media (max-width: 767px) {
      max-width: calc(100% - 15vw);
    }
  }

  .locationModal_head {
    margin-bottom: 23px;

    h5 {
      font-weight: 900;
      font-size: 20px;
      text-transform: uppercase;
      color: var(--white);
    }
  }

  .locationModal_form {
    .locationModal_each {
      margin-bottom: 25px;

      textarea {
        height: 90px !important;

        @media (max-width: 767px) {
          padding: 16px 16px;
        }

        @media (max-width: 479px) {
          height: 120px !important;
          font-size: 14px;
        }
      }

      &.locationModal_btn {
        padding-top: 14px;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
      }
    }
  }
}

.list_items_header_mbl {
  padding: 30px 20px;
  padding-top: 10px;
  margin: 0;

  a {
    display: table;
    color: #999;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &.active {
      color: #000;
    }
  }

  li {
    padding: 0;
    margin: 0;

    .MuiButtonBase-root {
      padding: 0;
      margin: 0;
      background: transparent !important;
    }
  }
}

.back_btn_wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 45px;
  margin-top: 45px;

  @media (max-width: 767px) {
    padding-top: 35px;
    margin-top: 35px;
  }
}

.bk_btn_main {
  .MuiButtonBase-root {
    border: 1px solid var(--color0BD3D3);
    color: var(--color0BD3D3);
    background-color: transparent;

    .MuiTypography-root {
      color: var(--color0BD3D3);
    }
  }
}

.passfield_global {
  .MuiInputBase-input {
    padding-right: 60px;
  }
}

.closes_div_sec {
  padding: 0 20px;
  padding-top: 10px;
  text-align: right;
  font-size: 20px;
  cursor: pointer;
}

.password {
  .open_eye {
    position: absolute;
    right: 17px;
    top: 14px;
  }
}

.input_search {
  padding: 2.5px 4px 2.5px 55px;
}

.MuiAutocomplete-input {
  padding: 2.5px 4px 2.5px 55px !important;
}

.MuiFormHelperText-root {
  color: #f44336;
  position: absolute;
  bottom: -25px;
}

.MuiAutocomplete-listbox {

  // background: linear-gradient( 158.45deg, #0bd3d3 17.08%, #ab2aa9 64.21%, #f890e7 97.73% );
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin: 0 10px 0 0;

    @media(max-width: 479px) {
      margin: 0 0 10px;
    }
  }

  .MuiButtonBase-root {
    position: absolute;
    right: 0;
    top: 2px;
    max-width: 200px;

    @media(max-width: 479px) {
      margin: 10px 0 0;
    }

    &:hover {
      color: #b73bac;
    }
  }
}

.MuiAvatar-root {
  margin: 0 10px 0 0;
}

.MuiAutocomplete-option {
  border-bottom: 1px solid #eee;
  font-size: 12px;

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin: 0 10px 0 0;
  }
}

.pac-target-input {
  padding: 0px 60px;
  background: transparent;
  width: 100%;
  padding: 0px 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 35px;
  height: 53px;
  color: var(--white);
}

// .pac-container .pac-logo{
//   border-radius: 10px !important;
// }

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

// .locationModal_auto{
//   z-index: 13;
//   .custom_cmm_btns{
//     margin-top: 20px;
//   }
// }

.custom_li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #c1c1c1;

  @media(max-width: 479px) {
    flex-direction: column;
  }

  button {
    margin-left: 10px;
    position: static !important;
    background-color: var(--color0BD3D3);

    color: #212b36;

    min-width: 90px;

    font-size: 12px;
  }
}

.auto_complte_popover {
  .MuiPaper-root {
    min-width: 300px !important;
    min-height: 55px !important;
    overflow: inherit !important;
    background: transparent !important;
    box-shadow: none !important;
    max-height: none !important;
    max-width: none !important;
  }
}

.autocomplete {
  .MuiInputBase-root {
    input {
      padding: 0 25px;
    }

    fieldset {
      display: none;
    }
  }

}

.suggestions {
  padding: 25px 25px 0 25px;

  li {
    padding: 15px 10px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      // border-bottom: 0;
    }

    button {
      min-width: auto;
      padding: 10px 20px;
      // pointer-events: none;
    }
  }

}

.click:hover {
  cursor: pointer;
}


.deleteModalWrapper {
  padding: 40px 50px;

  h4 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 5px 30px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 120px;
      margin: 0 5px;
      color: var(--white);

      &.yesBtn {
        background: var(--color0BD3D3);
      }

      &.noBtn {
        background: var(--primaryRed);
      }
    }
  }
}



.autocomplete_div {
  @media(max-width: 767px) {
    margin-right: 0;
  }
}

.MuiAccordionDetails-root {
  ol {
    padding-left: 22px;
  }
}

.accordion_listing_modal {
  li {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.204);
    padding: 15px 0px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    .accordion_listing_stack {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;

      @media(max-width: 767px) {
        display: block;
      }

      h5 {
        color: red;
        line-height: 1;
        margin: 0px 10px 0px 0px;

        @media(max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      p {
        color: rgb(255, 255, 255);
        line-height: 1;
      }
    }
  }
}

.complete_auto_custominpt {
  .MuiAutocomplete-input {
    padding-left: 20px !important;
  }
}

.playerdetails_modal{
  z-index: 9;

  .MuiPaper-root {
    max-width: 1030px;

    @media (max-width: 767px) {
      max-width: calc(100% - 15vw);
    }
  }

}

.locationModal_each_autocomplete{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .MuiAutocomplete-root{
    width: 350px;
  
  
  .MuiFormControl-root {
  .MuiInputBase-root{
  .MuiAutocomplete-endAdornment{
    button{
      top: 4px;
      // right: 50px;
      
  }
   }
  }
}
  }
  
}
