$primary: #2196f3;

$themes: (
    white: #fff,
    black: #000,
    activeColor: $primary,
    primaryBlue: $primary,
    primaryGreen: #00e676,
    primaryRed: #c62828,
    primaryOrange: #d84315,
    primaryTextColor: #212121,
    nextJSprogressBarColor: $primary,
    //******* EXTRA COLORS ================= // primary primaryLight: #e3f2fd,
        primaryMain: #2196f3,
    primaryDark: #1e88e5,
    primary200: #90caf9,
    primary800: #1565c0,

    // secondary
    secondaryLight: #ede7f6,
    secondaryMain: #673ab7,
    secondaryDark: #5e35b1,
    secondary200: #b39ddb,
    secondary800: #4527a0,

    // success Colors
    successLight: #b9f6ca,
    success200: #69f0ae,
    successMain: #00e676,
    successDark: #00c853,

    // error
    errorLight: #ef9a9a,
    errorMain: #f44336,
    errorDark: #c62828,

    // orange
    orangeLight: #fbe9e7,
    orangeMain: #ffab91,
    orangeDark: #d84315,

    // warning
    warningLight: #fff8e1,
    warningMain: #ffe57f,
    warningDark: #ffc107,

    // grey
    grey50: #fafafa,
    grey100: #f5f5f5,
    grey200: #eeeeee,
    grey300: #e0e0e0,
    grey500: #9e9e9e,
    grey600: #757575,
    grey700: #616161,
    grey900: #212121,
    //colours
    colorACACAC: #acacac,
    color0BD3D3: #0bd3d3,
    color100E48: #100e48,
    color0C1138: #0c1138,
    color20275C: #20275c,
    colorE0D6FF: #e0d6ff,
    colorF890E7: #f890e7,
    deleteRed:#FA8792,
);

// ** LIST OF ALL COLORS **
@mixin printVariables() {
    @each $key, $value in $themes {
        --#{$key}: #{$value};
    }
}

// ** EXPORT COLORS

@mixin exportVariables() {
    @each $key, $value in $themes {
        #{$key}: #{$value};
    }
}

:export {
    @include exportVariables();
}
